.main{
  
    width: 100%;
    height: 100vh;


}

.flexdiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo{
    width:60px;
    height: 60px;
    border-radius: 100%;
}

.mdaname{
    font-size: 15px;
    font-weight: 700;
    color:white;
    font-family:Georgia, 'Times New Roman', Times, serif;
}

.line{
    
    border: 0.1px solid rgb(227, 242, 230);
}

.linkss{
    margin-top: 100px;
}

.icons{
    color:white;
}

.slidelink{
    color:white;
    text-decoration: none;
}

.slidelink:hover{
    color:white;
    text-decoration: none;
}

.full{
    background-color: white;
}


.count{
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.title{
    color:white;

    font-size:15px;
    font-weight: 700;
}

.figure{
    color:white;

    font-size:18px;
    font-weight: bold;
}

.titledark{
    color:black;

    font-size:15px;
    font-weight: 700;
}

.figuredark{
    color:black;

    font-size:18px;
    font-weight: bold;
}

.summary{
    margin-top: 100px;
}

.card-header{
    background-color: #293D34;
    color:white;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}

.icontwo{
    font-size:35px;
}

.overdue {
    border-radius: 7px; 
    width: 80%;
    height: 13px;
   
    box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
  }
  .overdue::-webkit-progress-bar {
    background-color: grey;
    border-radius: 7px;
  }
  .overdue::-webkit-progress-value {
    background-color: red;
    border-radius: 7px;
    
  }


  /* pending */

  .pending {
    border-radius: 7px; 
    width: 80%;
    height: 13px;
   
    box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
  }
  .pending::-webkit-progress-bar {
    background-color: grey;
    border-radius: 7px;
  }
  .pending::-webkit-progress-value {
    background-color: #ffc107;
    border-radius: 7px;
    
  }

  /* completed */


  .completed {
    border-radius: 7px; 
    width: 80%;
    height: 13px;
   
    box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
  }
  .completed::-webkit-progress-bar {
    background-color: grey;
    border-radius: 7px;
  }
  .completed::-webkit-progress-value {
    background-color: #17a2b8;
    border-radius: 7px;
    
  }
  progress::-moz-progress-bar {
    /* style rules */
  }


  .execution {
    border-radius: 7px; 
    width: 80%;
    height: 13px;
   
    box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
  }
  .execution::-webkit-progress-bar {
    background-color: grey;
    border-radius: 7px;
  }
  .execution::-webkit-progress-value {
    background-color: #007bff;
    border-radius: 7px;
    
  }
  progress::-moz-progress-bar {
    /* style rules */
  }



  .complete {
    border-radius: 7px; 
    width: 80%;
    height: 13px;
   
    box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
  }
  .complete::-webkit-progress-bar {
    background-color: grey;
    border-radius: 7px;
  }
  .complete::-webkit-progress-value {
    background-color: #28a745;
    border-radius: 7px;
    
  }
  progress::-moz-progress-bar {
    /* style rules */
  }

  

.labeldiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.createbtn{
 background-color: #293D34;
 color: white;
 font-weight: bold;
}


thead{
    background-color: #293D34;
    color:white;
    font-size: 13px;
    font-weight: 400;
}

tbody{
    font-size: 13px;
    font-weight: 400;
}

.tablediv{
    margin-top: 30px;
}
.searchItem:hover{
    background-color: rgb(238, 238, 238);
    font-weight: 600;
    

}
.hoverme{
    color:white;
}
.hoverme:hover{
    background-color: #d4edda;
    border-color: #c3e6cb;
    color:#28a745;
}

.activelink{
    background-color: #d4edda;
    border-color: #c3e6cb;
    color:#28a745;
}

.showcasetext{
    color:white;
    font-weight: bold;
}

.rounded{
  border-radius: 15px;
}

.counttwo{
  height: 200px;
  background-color: #E3F2E5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.mainhead{
  font-size:15px;
  text-align: center;
  font-weight: 700;
}

.viewflex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 align-items: center;
}

.maincolor{
  color: rgb(41, 61, 52);
  font-size:14px;
  font-weight: bold;
  
}

.completion{
  background-color: rgb(41, 61, 52);
  color:white;
  font-size:14px;
}

.completion:hover{
  background-color: rgb(41, 61, 52);
  color:white;
  font-size:15px;
}